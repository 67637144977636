(function ($, app) {
    "use strict";

    app.m107 = function () {
        if($(".m107").length){
            // CHECKING WHILE FILLING IF THE INPUT IS NUMBER
            $("#area").on("keyup change",  function(e) {               
                $(this).val(function(index, value) {
                    return value
                    .replace(/\D/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
                });
                $('#input-size').text($(this).val())
                $(this).width($('#input-size').width());                
            });
            $('.calculate').on('click', function(e) {
                e.preventDefault();
                var area = $('#area').val().replaceAll(separator, ''),
                    period  = $('.period input:checked').val(),
                    ratio   = 0.7,
                    tpm     = 0.000222222,
                    hours   = area * ratio * tpm * period,
                    answer  = Math.round(hours).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" +separator);
                    $('.estimation span').text(answer);
                    $('html, body').animate({
                        scrollTop: $('.result').offset().top + (-200)
                    }, 500);
            })
        }






    };
})(jQuery, app);